
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import 'node_modules/@skillbox/front-box/dist/Inputs/UiAutopaymentMethod/style';

.price-v9__price-wrapper {
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    max-width: 496px;
  }
}

// Timer
.price-v9__timer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: var(--admin-color);
  text-align: center;
  background-color: var(--tooltip-bg-color);

  @include media(lg) {
    padding: 12px 20px;
  }

  span {
    font-feature-settings: 'tnum';
    font-variant-numeric: tabular-nums;
  }
}

.price-v9__timer-pic {
  flex-shrink: 0;
  margin-right: 8px;

  @include media(lg) {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
}

.price-v9__timer-desc {
  margin: 0;
}

// Price info
.price-v9__price-info {
  padding: 16px;

  @include media(lg) {
    padding: 20px;
  }
}

.price-v9__secondary-prices {
  @include ulres;

  display: grid;
  grid-column-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 4px;

  @include media(lg) {
    margin-bottom: 12px;
  }

  &--two {
    grid-template-rows: auto auto;
  }
}

.price-v9__secondary-price {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1/2;

  &:not(&--old) {
    grid-row: 2/-1;
  }

  &--old {
    grid-row: 1/2;
    color: var(--text-addition-color);

    span {
      text-decoration: line-through;
    }
  }

  span {
    margin-right: 4px;
  }
}

.price-v9__sale {
  display: inline-flex;
  grid-row: 1/-1;
  grid-column: 2/-1;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  min-width: 36px;
  padding: 4px 11px 5px;
  border: 1px solid transparent;
  border-radius: 20px;
  color: var(--admin-color);
  background-color: var(--tooltip-bg-color);

  @include media(lg) {
    min-width: 48px;
  }
}

.price-v9__primary-price {
  display: flex;
  flex-wrap: wrap;
}

.price-v9__currency {
  display: block;
  margin-top: -1px;
  margin-left: 3px;
}

.price-v9__credit-list {
  @include ulres;

  margin-top: 4px;
}

.price-v9__credit-item {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.price-v9__price-desc {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 16px 1fr;
  margin-top: 16px;
  margin-bottom: 0;

  @include media(lg) {
    margin-top: 20px;
  }
}

.price-v9__sale-info {
  span {
    display: inline-flex;
  }
}

// Bullets
ul.price-v9__features {
  margin-top: 24px;
  margin-bottom: 0;

  @include media(lg) {
    margin-top: 40px;
  }

  @include media(lg, lg-max) {
    width: 496px;
  }
}

.price-v9__step {
  @include media(md, lg-max) {
    display: grid;
    grid-column-gap: 84px;
    grid-template-columns: 1fr 304px;
  }

  @include media(lg, lg-max) {
    grid-column-gap: 112px;
    grid-template-columns: 1fr 400px;
  }

  &:not(:last-child) {
    margin-bottom: 28px;

    @include media(lg) {
      margin-bottom: 32px;
    }
  }
}

.price-v9__subtitle {
  margin-bottom: 32px;

  @include media(md) {
    margin-bottom: 0;
  }

  @include media(xl) {
    margin-bottom: 40px;
  }
}

.price-v9__contacts {
  display: flex;
  flex-direction: column;

  @include media(md, lg-max) {
    grid-column: 2/3;
  }
}

// Сноска для Scrum Alliance
.price-v9__information {
  margin-top: 18px;
  margin-bottom: 0;
  color: var(--admin-color);
  text-align: center;

  @include media(md) {
    width: 667px;
    margin: 28px auto 0;
  }

  @include media(lg) {
    grid-column: 1/-1;
    width: 799px;
  }
}

[data-theme='dark'] .price-v9__information {
  color: var(--text-main-color);
}

// Переопределяем стандартные стили тултипа
.price-v9__tooltip {
  .price-v9__tooltip-btn {
    svg {
      color: var(--icon-addition-color);
    }

    @include hover {
      svg {
        color: var(--icon-main-color);
      }
    }
  }

  .price-v9__tooltip-btn:focus-visible svg {
    @include media(lg) {
      color: var(--icon-main-color);
    }
  }
}

.is-safari .price-v9__tooltip-btn:focus svg {
  @include media(lg) {
    color: var(--icon-main-color);
  }
}

.price-types {
  margin-bottom: 32px;
}

.price-types__method {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
